import React from "react";
import { Route } from "react-router-dom";
  
const MainAdminComponent = React.lazy(() => import('./MainAdmin'));
const UsuariosComponent = React.lazy(() => import('./Usuarios/Usuarios'))

const RouterAdmin = (<Route path="admin" element={<MainAdminComponent />}>
                        <Route path="" element={<UsuariosComponent />} />
                        <Route path="usuarios" element={<UsuariosComponent />} />
                    </Route>)

export default RouterAdmin;
