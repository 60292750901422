import React from "react";
import { Route } from "react-router-dom";
import RouterAdmin from './Admin/RouterAdmin';

const MainComponent = React.lazy(() => import('./Main'));
const DashboardComponent = React.lazy(() => import('./Dashboard/Dashboard'));
const ProductosComponent = React.lazy(() => import('./Productos/Productos'));
const SeriesComponent = React.lazy(() => import('./Series/index'));
const RouterMain = (
    <Route path="/main" element={<MainComponent />}>
        <Route path="" element={<DashboardComponent />} />
        <Route path="productos" element={<ProductosComponent />} />
        <Route path="series" element={<SeriesComponent />} />
        { RouterAdmin }
    </Route>)


export default RouterMain